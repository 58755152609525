/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

const LoginForm = () => {
    return (
        <>
            <form name="contactForm" id='contact_form' class="form-border py-5" method="post" action='https://madebydesignesia.com/themes/gigaland/blank.php'>
                <h3>Login to your account</h3>

                <div class="field-set">
                    <label>Username</label>
                    <input type='text' name='name' id='name' class="form-control" placeholder="" />
                </div>


                <div class="field-set">
                    <label>Password</label>
                    <input type='text' name='email' id='email' class="form-control" placeholder="" />
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <p class='text-dark'> <Link to='/forgot'>Forgot Password</Link></p>
                    <p class='text-dark'>You don't have an account. <Link to='/register'>Register</Link></p>
                </div>

                <div className=''>
                    <input type='submit' id='send_message' value='Login' class="btn btn-main color-2 w-100" />
                </div>
                <div class="spacer-single"></div>

                {/* <!-- social icons --> */}
                <ul class="list s3">
                    <li>Or login with:</li>
                    <li><a href="#">Facebook</a></li>
                    <li><a href="#">Google</a></li>
                    <li><a href="#">Instagram</a></li>
                </ul>
                {/* <!-- social icons close --> */}


            </form>
        </>
    )
}

export default LoginForm