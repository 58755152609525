/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import Banner from '../../Components/Home/Banner'
import ColllectionsCard from '../../Components/Home/ColllectionsCard'

const Home = () => {
    return (
        <>
            <div className="no-bottom no-top" id="content">
                <div id="top"></div>
                <Banner />
                <section id="section-trending" className="pt40 no-bottom">
                    <div className="container">
                        <div className="row">
                                <h2>Latest Collection</h2>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/1.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/2.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/3.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/4.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/5.jpg"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/6.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/1.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/2.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/3.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/4.png"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/5.jpg"/>
                            </div>
                            <div className="col-lg-4">
                                <ColllectionsCard src="/assets/images/cards/6.png"/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Home