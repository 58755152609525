import React from 'react'
import Banners from '../../Components/Banner/Banners'
import AllCollection from '../../Components/Collection/AllCollection'

const Collection = () => {
    return (
        <>

            <Banners name="All Collection"/>
            <AllCollection/>


        </>
    )
}

export default Collection