import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./Layout/Index";
import Home from "./Pages/Home/Home";
import Collection from "./Pages/Collection/Collection";
import Single from "./Pages/Collection/Single";
import Detail from "./Pages/Collection/Detail";
import SwapsPage from "./Pages/Swaps/SwapsPage";
import AboutPage from "./Pages/About/AboutPage";
import Login from "./Pages/Auth/Login/Login";
import Register from "./Pages/Auth/Register/Register";
import Forgot from "./Pages/Auth/Forgot/Forgot";
import Reset from "./Pages/Auth/Reset/Reset";


function App() {
  return (
    <>
      <BrowserRouter>
        <Index>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/collection" element={<Collection />} />
            <Route exact path="/collection/:name" element={<Single />} />
            <Route exact path="/detail/:id" element={<Detail />} />
            <Route exact path="/swaps" element={<SwapsPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgot" element={<Forgot />} />
            <Route exact path="/reset" element={<Reset />} />
          </Routes>
        </Index>
      </BrowserRouter>
    </>
  );
}

export default App;
