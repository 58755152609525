import React from "react";
import Banners from "../../Components/Banner/Banners";
import About from "../../Components/ABout/About";

const AboutPage = () => {
  return (
    <>
      <Banners name="About" />
      <About />
    </>
  );
};

export default AboutPage;
