import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <section
        id="section-hero"
        aria-label="section"
        className="no-top no-bottom vh-100 my-5"
        data-bgimage="url(images/background/bg-shape-1.jpg) bottom"
        style={{
          background:
            'url("images/background/bg-shape-1.jpg") center bottom / cover',
        }}
      >
        <div className="v-center" style={{ backgroundSize: "cover" }}>
          <div className="container" style={{ backgroundSize: "cover" }}>
            <div
              className="row align-items-center"
              style={{ backgroundSize: "cover" }}
            >
              <div className="col-md-6" style={{ backgroundSize: "cover" }}>
                <div
                  className="spacer-single"
                  style={{ backgroundSize: "cover" }}
                />
                <div
                  className="spacer-10"
                  style={{ backgroundSize: "cover" }}
                />
                <h1
                  className="wow fadeInUp animated"
                  data-wow-delay=".75s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.75s",
                    animationName: "fadeInUp",
                  }}
                >
                  About us
                </h1>
                <p
                  className="wow fadeInUp lead animated"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <b>
                    Got Need Swap is an online community for collectors of
                    stickers and trading cards to swap their extras and finish
                    their collections.
                  </b>
                </p>
                <p
                  className="wow fadeInUp lead animated"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1s",
                    animationName: "fadeInUp",
                  }}
                >
                  Years ago we were allowed to take our stickers to school and
                  flick through a pile of them with our friends - got, got, got,
                  need! Then you look through their pile of swaps too and
                  arrange a swap!
                </p>
                <p
                  className="wow fadeInUp lead animated"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1s",
                    animationName: "fadeInUp",
                  }}
                >
                  These days schools tend not to want their pupils to do this so
                  we have developed an online community to replace this fun,
                  playground activity.
                </p>
                <p
                  className="wow fadeInUp lead animated"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1s",
                    animationName: "fadeInUp",
                  }}
                >
                  <Link to={"/signup"}>Sign up</Link>
                  or
                  <Link to={"/login"}>log in</Link>
                  now to join our online swapping community!
                </p>
                <p
                  className="wow fadeInUp lead animated"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1s",
                    animationName: "fadeInUp",
                  }}
                >
                  We are constanly working on the site, adding new collections,
                  developing new features to make swapping easier.
                </p>
                <p
                  className="wow fadeInUp lead animated"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "1s",
                    animationName: "fadeInUp",
                  }}
                >
                  You can contact the developers at abcd@.com.uk if you have any
                  questions, suggestions or problems.
                </p>
                <div
                  className="spacer-10"
                  style={{ backgroundSize: "cover" }}
                />
                <div className="mb-sm-30" style={{ backgroundSize: "cover" }} />
              </div>
              <div
                className="col-md-6 xs-hide"
                style={{ backgroundSize: "cover" }}
              >
                <div className="col-lg-12 col-md-6 mb30">
                  <div className="bloglist item">
                    <div className="post-content d-flex align-items-center">
                      <div className="post-image">
                        <img
                          alt=""
                          src="/assets/images/news/news-1.jpg"
                          className="lazy"
                        />
                      </div>
                      <div className="post-text ms-4">
                        <span className="p-tagline">Tips &amp; Tricks</span>
                        <span className="p-date">October 28, 2020</span>
                        <h4>
                          <a href="news-single.html">
                            The next big trend in crypto
                            <span />
                          </a>
                        </h4>
                        <p>
                          Dolore officia sint incididunt non excepteur ea mollit
                          commodo ut enim reprehenderit cupidatat labore ad
                          laborum consectetur consequat...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 mb30">
                  <div className="bloglist item">
                    <div className="post-content d-flex align-items-center">
                      <div className="post-image">
                        <img
                          alt=""
                          src="/assets/images/news/news-1.jpg"
                          className="lazy"
                        />
                      </div>
                      <div className="post-text ms-4">
                        <span className="p-tagline">Tips &amp; Tricks</span>
                        <span className="p-date">October 28, 2020</span>
                        <h4>
                          <a href="news-single.html">
                            The next big trend in crypto
                            <span />
                          </a>
                        </h4>
                        <p>
                          Dolore officia sint incididunt non excepteur ea mollit
                          commodo ut enim reprehenderit cupidatat labore ad
                          laborum consectetur consequat...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
