import React from 'react'
import { Link } from 'react-router-dom'

const RegisterForm = () => {
    return (
        <>
            <form name="contactForm" id='contact_form' class="form-border" method="post" action='https://madebydesignesia.com/themes/gigaland/blank.php'>

                <div class="row">

                    <div class="col-md-6">
                        <div class="field-set">
                            <label>Name:</label>
                            <input type='text' name='name' id='name' class="form-control" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="field-set">
                            <label>Email Address:</label>
                            <input type='text' name='email' id='email' class="form-control" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="field-set">
                            <label>Choose a Username:</label>
                            <input type='text' name='username' id='username' class="form-control" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="field-set">
                            <label>Phone:</label>
                            <input type='text' name='phone' id='phone' class="form-control" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="field-set">
                            <label>Password:</label>
                            <input type='text' name='password' id='password' class="form-control" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="field-set">
                            <label>Re-enter Password:</label>
                            <input type='text' name='re-password' id='re-password' class="form-control" />
                        </div>
                    </div>


                    <div class="col-md-12">

                        <div className='d-flex justify-content-between align-items-center'>
                            <input type='submit' id='send_message' value='Register' class="btn btn-main color-2" />
                            <p class='text-dark'>Already You have an account. <Link to='/login'>Login</Link></p>
                        </div>
                        <div class="clearfix"></div>

                    </div>

                </div>
            </form>
        </>
    )
}

export default RegisterForm