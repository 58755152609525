/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

const DetailCollection = () => {
    return (
        <>
            <div className='container'>
                <div class="row fadeIn pt-5">
                    <div class="col-lg-12">
                        <div class="items_filter w-100">
                            <form action="https://madebydesignesia.com/themes/gigaland/blank.php" class="row form-dark w-100" id="form_quick_search" method="post" name="form_quick_search">
                                <div class="col text-center w-100">
                                    <input class="form-control " id="name_1" name="name_1" placeholder="search item here..." type="text" /> <a href="#" id="btn-submit"><i class="fa fa-search bg-color-secondary"></i></a>
                                    <div class="clearfix"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className='row pb-5'>
                    <h2>Panini WWE Adrenalyn XL Trading Card 2024
                        Panini WWE Smackdown</h2>
                    <div className='col-lg-8'>
                        <Table striped="columns" bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Card Type</th>
                                    <th>For Sale</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>AJ Styles - Smackdown</td>
                                    <td>Base Card</td>
                                    <td>
                                        <button className='btn btn-primary'>
                                            £ 2.00
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </Table>
                    </div>
                    <div className='col-lg-4'>
                        <h2>Start Swapping</h2>
                        <p>
                            <Link to="/signup">Sign up</Link> or <Link to='/login'>Login</Link> to your Got Need Swap account to start trading with other members today.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailCollection