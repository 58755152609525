/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const ResetForm = () => {
    return (
        <>
            <form name="contactForm" id='contact_form' class="form-border py-5" method="post" action='https://madebydesignesia.com/themes/gigaland/blank.php'>
                <h3>Reset Password</h3>

                <div class="field-set">
                    <label>Password</label>
                    <input type='Password' name='password'  class="form-control" placeholder="" />
                </div>
                <div class="field-set">
                    <label>Re-enter Password</label>
                    <input type='Password' name='password'  class="form-control" placeholder="" />
                </div>
                <div className='pt-4'>
                    <input type='submit' id='send_message' value='Reset' class="btn btn-main color-2 w-100" />
                </div>
                <div class="spacer-single"></div>

                

            </form>
        </>
    )
}

export default ResetForm