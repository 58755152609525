import React from 'react'
import HomeSlider from '../Sliders/HomeSlider'
import { Link } from 'react-router-dom'

const Banner = () => {
    return (
        <>
            <section id="section-hero" className="no-top no-bottom mt90 sm-mt-0" aria-label="section">
                <div className="m-5 padding30 br-15 bg-custom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-sm-30">
                                <h1>Explore, collect, and sell extraordinary <span className="text-gradient">NFTs</span></h1>
                                <p className="lead">
                                    Welcome to the future, you can buy and sell awesome artworks form here. The world largest digital marketplace for non-fungible tokens.</p>
                                <Link to="/register" className="btn-main btn-lg">Start Swapping</Link>&nbsp;&nbsp;
                                <Link to={'/swaps'} className="btn-main btn-lg btn-light">How To Swap</Link>
                            </div>

                            <div className="col-lg-6">
                                <div className="d-carousel">
                                    <HomeSlider />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner