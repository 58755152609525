/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isTrue, setIsTrue] = useState(false);
  return (
    <>
      <header className={`header-light scroll-light ${isTrue && "h-355"}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex sm-pt10">
                <div className="de-flex-col">
                  <div className="de-flex-col">
                    {/* <!-- logo begin --> */}
                    <div id="logo">
                      <a href="/">
                        <img
                          style={{ width: "150px", height: "130px" }}
                          alt=""
                          className="logo"
                          src="/assets/images/logo-7-light.png"
                        />
                        <img
                          style={{ width: "150px", height: "130px" }}
                          alt=""
                          className="logo-2"
                          src="/assets/images/logo-7.png"
                        />
                      </a>
                    </div>
                    {/* <!-- logo close --> */}
                  </div>
                  <div className="de-flex-col">
                    <input
                      id="quick_search"
                      className="xs-hide"
                      name="quick_search"
                      placeholder="search item here..."
                      type="text"
                    />
                  </div>
                </div>
                <div className="de-flex-col header-col-mid">
                  {/* <!-- mainmenu begin --> */}
                  <ul id="mainmenu">
                    <li>
                      <Link to="/">
                        Home<span></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/about"}>
                        About<span></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/collection">
                        Collection<span></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/swaps"}>
                        How To Swaps<span></span>
                      </Link>
                    </li>
                  </ul>
                  <div className="menu_side_area">
                    <Link to="/login" className="btn-main btn-wallet">
                      <i className="icon_wallet_alt"></i>
                      <span>Login</span>
                    </Link>
                    <span
                      id="menu-btn"
                      onClick={() => setIsTrue(!isTrue)}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
