/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ColllectionsCard from '../Home/ColllectionsCard'
import { Link } from 'react-router-dom'

const SingleCollection = () => {
    return (
        <>
            <div className='pt-5'>
                <div class="container">
                    <div class="row fadeIn">
                        <div class="col-lg-12">
                            <div class="items_filter w-100">
                                <form action="https://madebydesignesia.com/themes/gigaland/blank.php" class="row form-dark w-100" id="form_quick_search" method="post" name="form_quick_search">
                                    <div class="col text-center w-100">
                                        <input class="form-control " id="name_1" name="name_1" placeholder="search item here..." type="text" /> <a href="#" id="btn-submit"><i class="fa fa-search bg-color-secondary"></i></a>
                                        <div class="clearfix"></div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>
                    {/* new collection */}
                    <div className='League Trading'>
                        <h2>New Collections for 2024</h2>
                        <div className='row'>
                            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <Link to={`/detail/${1}`}>
                                    <ColllectionsCard src="/assets/images/cards/1.png"/>
                                </Link>
                            </div>
                            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <Link to={`/detail/${2}`}>
                                    <ColllectionsCard src="/assets/images/cards/2.png"/>
                                </Link>
                            </div>
                            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <Link to={`/detail/${3}`}>
                                    <ColllectionsCard src="/assets/images/cards/3.png"/>
                                </Link>
                            </div>
                            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <Link to={`/detail/${4}`}>
                                    <ColllectionsCard src="/assets/images/cards/4.png"/>
                                </Link>
                            </div>

                        </div>
                    </div>
             

                    <div class="col-md-12 text-center mb-5">
                        <a href="#" id="loadmore" class="btn-main  fadeInUp lead">Load more</a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SingleCollection