import React from "react";

const Swaps = () => {
  return (
    <>
      <div className="no-bottom no-top mt100 mb100" id="content">
        <div className="container">
          <h2> Safely swap your stickers or trading cards in nine easy steps A few</h2>
          <p>simple things to remember and steps to follow to get you going.</p>
        </div>
        <section
          id="section-intro"
          className="no-top no-bottom"
          style={{ backgroundSize: "cover" }}
        >
          <div className="container" style={{ backgroundSize: "cover" }}>
            <div className="row" style={{ backgroundSize: "cover" }}>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_wallet animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 1
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      LOG IN
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      The first thing you need to do is join the site and login.
                    </p>
                  </div>
                  <i className="wm icon_wallet" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 2
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      YOUR COLLECTION
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Select the collection you want to complete from our
                      available collections.
                    </p>
                  </div>
                  <i className="wm icon_cloud-upload_alt" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_tags_alt animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 3
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      CHOOSE SECTION
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Choose the club or section of the collection you want to
                      update.
                    </p>
                  </div>
                  <i className="wm icon_tags_alt" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_wallet animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 4
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      ADD CARDS
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Use the plus or minus buttons to add new cards to your
                      collection.
                    </p>
                  </div>
                  <i className="wm icon_wallet" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 5
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      CHECK FOR SWAPS
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Either use the panel next to the table or click ‘Show All
                      Available Swaps’.
                    </p>
                  </div>
                  <i className="wm icon_cloud-upload_alt" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_tags_alt animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 6
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      SEND EMAIL
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Send an email to the other member with a message if you
                      want to swap with them.
                    </p>
                  </div>
                  <i className="wm icon_tags_alt" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_wallet animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 7
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      COMMUNICATE
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      All communication with other members is done through your
                      own emails. Remember to stay safe!
                    </p>
                  </div>
                  <i className="wm icon_wallet" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 8
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      UPDATE SITE
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      Once you have agreed your swap, posted off your cards and
                      received the new ones you need to update your collection
                      on the website.
                    </p>
                  </div>
                  <i className="wm icon_cloud-upload_alt" />
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-sm-30"
                style={{ backgroundSize: "cover" }}
              >
                <div
                  className="feature-box f-boxed style-3 my-4"
                  style={{ backgroundSize: "cover" }}
                >
                  <i
                    className="wow fadeInUp bg-color-2 i-boxed icon_tags_alt animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  />
                  <div className="text" style={{ backgroundSize: "cover" }}>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      Step 9
                    </h4>
                    <h4
                      className="wow fadeInUp animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInUp",
                      }}
                    >
                      ADD FEEDBACK
                    </h4>
                    <p
                      className="wow fadeInUp animated"
                      data-wow-delay=".25s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      In your account page you can see recent contacts. When you
                      dismiss a contact you can add feedback on the member you
                      swapped with.
                    </p>
                  </div>
                  <i className="wm icon_tags_alt" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Swaps;
