import React from 'react'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'

const Index = ({ children }) => {
    return (
        <>
            <div id="wrapper">
                <Navbar />
                {children}
                <Footer />
            </div>
        </>
    )
}

export default Index