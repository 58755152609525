import React from 'react'
import Banners from '../../Components/Banner/Banners'
import SingleCollection from '../../Components/Collection/SingleCollection'

const Single = () => {
    return (
        <>

            <Banners name="single Collection" />
            <SingleCollection />

        </>
    )
}

export default Single